import * as Auth from './auth.context';
import * as Toasts from './toast.context';

export interface BaseProviderInterface {
    children: JSX.Element[];
}

export const MainProvider = ({ children }: BaseProviderInterface) => {
    return (
        <Auth.AuthProvider>
            <Toasts.ToastsProvider>
                {children}
            </Toasts.ToastsProvider>
        </Auth.AuthProvider>
    )
}

export {
    Auth, Toasts
}
