import { NavItem, NavItemExtended } from './components/navbar/navbar';

export const navigation: Array<NavItemExtended> = [
    {
        label: 'Dashboard',
        href: '/'
    },
    {
        label: 'Swap',
        href: '/swap'
    },
    {
        label: 'Funds',
        href: '/funds'
    },
    {
        label: 'Wallets',
        href: '/wallets'
    },
    {
        label: 'CEX Accounts',
        href: '/cex-accounts'
    },
    {
        label: 'Rules',
        href: '#',
        children: [
            {
                label: 'Owners',
                href: '/owners',
            },
            {
                label: 'Stablecoins',
                href: '/stablecoins',
            },
            {
                label: 'Similarities',
                href: '/similarities',
            },
            {
                label: 'Protocol Preferences',
                href: '/protocolpreferences',
            },
            {
                label: 'Price Adjustments',
                href: '/priceadjustments',
            },
            {
                label: 'Strategies',
                href: '/strategies',
            },
        ],
    },
]
export const userNavBar: Array<NavItem> = [
    {
        href: '/organization/profile',
        label: 'Organization'
    },
    {
        href: '/user/profile',
        label: 'Profile'
    },
    {
        href: '/billing',
        label: 'Billing'
    }
]
