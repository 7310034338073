import { TokenObtainPair } from '../../../Types/Api';
import { ApiService } from '../../services/api.service';

export interface LoginRequestInterface extends TokenObtainPair {}
export interface RegisterTenantRequestInterface {
    name: string;
    subdomain: string;
}
export interface RegisterTenantUserRequestInterface {
    email: string;
    tenant: string;
    username: string;
}

export interface RegisterRequest {
    tenant: {
        name: string;
        subdomain: string;
    },
    user: {
        username: string;
        email: string;
    },
}

export interface PasswordResetRequest {
    email?: string;
    username?: string;
    subdomain: string;
}

export const AuthLoginRequest = (data: LoginRequestInterface) => ApiService.post('/token/', data, false);
export const AuthTokenIsValid = async (token: string) => ApiService.post('/token/verify/', { token: token }, false);
export const AuthRefreshTokenRequest = async (token: string) => ApiService.post('/token/refresh/', { refresh: token }, false);
export const AuthGetTenantDashboardUrl = async () => ApiService.get(`/quicksight/config/`);

export const CSRFTokenFetch = async () => ApiService.get('/token/csrf/', {});

export const Register = async (payload: RegisterRequest) => ApiService.post(`/signup/`, payload, false);
export const AuthPasswordReset = async (payload: PasswordResetRequest) => ApiService.post(`/passwordreset/`, payload, false);
