import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { Auth, Toasts } from '../../state';
import { Spinner } from '../../components/spinner/spinner';
import { AuthGetTenantDashboardUrl } from '../auth/auth.requests';

import './style.css';

export default function DashboardScreen() {
    const { userId } = useContext<Auth.AuthContextInterface>(Auth.AuthContext);
    const { addToast } = useContext<Toasts.ToastsContextInterface>(Toasts.ToastsContext);

    const { data, isFetching } = useQuery<any[], any, any, any>({
        enabled: !!userId,
        queryKey: [ 'dashboard' ],
        queryFn: () => AuthGetTenantDashboardUrl(),
        onError: (err: Error) => {
            addToast({
                message: err.message,
                type: 'error'
            });
        },
        refetchOnWindowFocus: false,
    });

    return (
        <>
            {isFetching && (<Spinner/>)}
            {!isFetching && data && data.url && (<iframe title="quickSightIframe" width={'100%'} height={'100%'} src={data.url} />)}
        </>
    )
}
