import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { AlertStatus } from '@chakra-ui/alert';

import { BaseProviderInterface } from './index';

export interface ToastInterface {
    id?: string;
    message: string;
    type: AlertStatus,
    ttl?: number;
}

export interface ToastsContextInterface {
    toasts: ToastInterface[];
    addToast: (toast: ToastInterface) => void;
    removeToast: (id: string) => void,
}

const defaultToastsContext: ToastsContextInterface = {
    toasts: [],
    addToast: (toast: ToastInterface) => {
    },
    removeToast: (id: string) => {
    },
};

const TOAST_TTL = 4000;
export const ToastsContext = React.createContext({ ...defaultToastsContext });

export const ToastsProvider = ({ children }: BaseProviderInterface) => {
    const [ toasts, setToasts ] = useState<ToastInterface[]>([]);

    const addToast = (toast: ToastInterface) => {
        setToasts([
            ...toasts, {
                id: uuidv4(),
                ttl: TOAST_TTL,
                ...toast,
            }
        ]);
    };
    const removeToast = (toastId: string) => {
        const newToasts = toasts.filter(toast => toast.id !== toastId);
        setToasts([ ...newToasts ]);
    }

    return (
        <ToastsContext.Provider value={{ toasts, addToast, removeToast }}>
            {children}
        </ToastsContext.Provider>
    )
}
