import { Flex, Spinner as ChakraSpinner } from '@chakra-ui/react';

export const Spinner = () => (
    <Flex justifyContent="center" p={10}>
        <ChakraSpinner
            thickness='4px'
            speed='0.65s'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
        />
    </Flex>
)
