import { LocalStorage } from './local-storage.service';
import { AUTH_ACCESS_TOKEN_KEY } from '../app.constants';

type buildSettingsArgs = {
    token?: string;
    csrf?: boolean;
    credentials?: RequestCredentials;
    settings?: {
        headers: Record<string, string>
    };
}

async function buildSettings({ token, settings, csrf = false }: buildSettingsArgs): Promise<RequestInit> {
    const headers: Record<string, string> = {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    };

    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }

    if (csrf) {
        headers["X-CSRFTOKEN"] = await getCSRFToken();
    }
    return {
        ...(settings || {}),
        headers: {
            ...headers,
            ...((settings && settings.headers) || {}),
        },
    };
}

async function handleResponse(response: any) {
    if (response.status === 206) {
        return response.blob();
    }

    if (response.ok) {
        try {
            const json = await response.json();
            return json;
        } catch (e) {
            return {};
        }
    } else {
        throw await response.json();
    }
}

export const createApiService = (baseUrl: string | undefined) => {
    console.log('baseURL: ', baseUrl);
    if (!baseUrl) throw new Error('Service Settings Have Not Provided');
    return {
        get: async <ResponseType>(url: string, settings?: any): Promise<ResponseType> => {
            const token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY) ?? '';
            const requestSettings = await buildSettings({ token });
            const params = new URLSearchParams(settings ? settings.params : {}).toString();
            return fetch(`${baseUrl}${url}?${params}`, {
                method: 'GET',
                ...requestSettings
            })
                .then(handleResponse);
        },
        post: async <ResponseType, RequestDataType>(uri: string, data: RequestDataType, csrf = true): Promise<ResponseType> => {
            const token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY) ?? '';
            const requestSettings = await buildSettings({ token, csrf });
            if (data instanceof FormData) {
                const headers = new Headers(requestSettings.headers);
                headers.delete("Content-Type");
                requestSettings.headers = headers;
            }

            return fetch(`${baseUrl}${uri}`, {
                method: 'POST',
                ...requestSettings,
                body: data instanceof FormData ? data : JSON.stringify(data),
            })
                .then(handleResponse);
        },
        put: async <ResponseType, RequestDataType>(uri: string, data: RequestDataType): Promise<ResponseType> => {
            const token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY) ?? '';
            const requestSettings = await buildSettings({ token, csrf: true });
            if (data instanceof FormData) {
                const headers = new Headers(requestSettings.headers);
                headers.delete("Content-Type");
                requestSettings.headers = headers;
            }

            return fetch(`${baseUrl}${uri}`, {
                method: 'PUT',
                ...requestSettings,
                body: data instanceof FormData ? data : JSON.stringify(data),
            })
                .then(handleResponse);
        },
        patch: async <ResponseType, RequestDataType>(uri: string, data: RequestDataType): Promise<ResponseType> => {
            const token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY) ?? '';
            const requestSettings = await buildSettings({ token, csrf: true });

            if (data instanceof FormData) {
                const headers = new Headers(requestSettings.headers);
                headers.delete("Content-Type");
                requestSettings.headers = headers;
            }

            return fetch(`${baseUrl}${uri}`, {
                method: 'PATCH',
                ...requestSettings,
                body: data instanceof FormData ? data : JSON.stringify(data),
            })
                .then(handleResponse);
        },
        delete: async <ResponseType, RequestDataType>(uri: string, data: RequestDataType): Promise<ResponseType> => {
            const token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY) ?? '';
            const requestSettings = await buildSettings({ token, csrf: true });

            return fetch(`${baseUrl}${uri}`, {
                method: 'DELETE',
                ...requestSettings,
                body: JSON.stringify(data),
            })
                .then(handleResponse);
        },
    }
}


const backendUrlParts = new URL(window.location.href);
const domain = process.env.REACT_APP_BACKEND_URL ? process.env.REACT_APP_BACKEND_URL : backendUrlParts.hostname;
const url = 'https://' + domain + '/api';
const AppBackendUrl = process.env.REACT_APP_ENVIRONMENT === 'production' ? url : process.env.REACT_APP_LOCAL_BACKEND_URL;
export const ApiService = createApiService(AppBackendUrl);

async function getCSRFToken() {
    const { token } = await ApiService.get<{ token: string }>('/token/csrf');
    return token;
}
