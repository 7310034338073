const createLocalStorageService = () => {
    return {
        getItem: (key: string) => {
            return localStorage.getItem(key);
        },
        setItem: (key: string, payload: string) => {
            return localStorage.setItem(key, payload);
        },
        removeItem: (key: string) => {
            localStorage.removeItem(key);
        }
    }
}

export const LocalStorage = createLocalStorageService();
