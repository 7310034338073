import { useToast } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { Toasts } from '../../state';

export const ToastsBar = () => {
    const toast = useToast();
    const { toasts, removeToast } = useContext(Toasts.ToastsContext);

    useEffect(() => {
        toasts.forEach(toastItem => {
            toast({
                id: toastItem.id,
                title: toastItem.message,
                status: toastItem.type
            });

            setTimeout(() => {
                removeToast(toastItem.id as string);
            }, 0);
        });
    }, [toasts, toast, removeToast]);

    return (
        <></>
    )
}
