import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
    fonts: {
        // heading: `'SF Automaton', sans-serif`,
        // body: `'SF Automaton', sans-serif`,
    },
    colors: {
        "primary": {
            "50": "#EDFDFD",
            "100": "#C4F1F9",
            "200": "#9DECF9",
            "300": "#76E4F7",
            "400": "#0BC5EA",
            "500": "#00B5D8",
            "600": "#00A3C4",
            "700": "#0987A0",
            "800": "#086F83",
            "900": "#065666"
        },
    },
    config: {
        initialColorMode: 'dark',
        useSystemColorMode: false,
        // colors: {
        //     "primary": {
        //         "50": "#f8f7f8",
        //         "100": "#e3dfe4",
        //         "200": "#cbc3ce",
        //         "300": "#afa2b2",
        //         "400": "#9f8fa3",
        //         "500": "#8a778f",
        //         "600": "#78627e",
        //         "700": "#654c6c",
        //         "800": "#583e60",
        //         "900": "#46284e"
        //     },
        //     bg: {
        //         "50": "#f7f7f8",
        //         "100": "#dee0e4",
        //         "200": "#c2c6cc",
        //         "300": "#a0a6b0",
        //         "400": "#8e95a0",
        //         "500": "#757e8b",
        //         "600": "#606a7a",
        //         "700": "#4a5567",
        //         "800": "#3c485b",
        //         "900": "#263449"
        //     },
        //     lightBg: '#006466'
        // },
        // styles: {
        //     global: (props: Record<string, any>) => ({
        //         body: {
        //             bg: mode(props.theme.colors.bg['50'], props.theme.colors.bg['800'])(props),
        //         }
        //     })
        // }
    }
});

export default theme;
