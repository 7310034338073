import jwt_decode from 'jwt-decode';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useEffect, useState } from 'react';

import { LocalStorage } from '../services/local-storage.service';
import { AUTH_ACCESS_TOKEN_KEY, AUTH_REFRESH_TOKEN_KEY } from '../app.constants';
import { AuthRefreshTokenRequest, AuthTokenIsValid } from '../screens/auth/auth.requests';

export const AUTH_URL = '/auth/login';
export const REGISTER_URL = '/auth/register';
export const PASSWORD_RESET_URL = '/auth/password-reset';
export const OPEN_URLS = [AUTH_URL, REGISTER_URL, PASSWORD_RESET_URL, '/swap'];

export interface AuthContextInterface {
    userId: number | null;
    AUTH_URL: string;
    subdomain: string;
    isLoggedIn: boolean;
    logout: () => void;
    setIsLoggedIn: (isLoggedIn: boolean) => void;
}

const defaultAuthContext: AuthContextInterface = {
    AUTH_URL,
    userId: null,
    subdomain: '',
    isLoggedIn: false,
    setIsLoggedIn: () => {
    },
    logout: () => {
    }
};

interface AuthProviderPropsInterface {
    children: JSX.Element;
}

const hostname = window.location.hostname;

export const AuthContext = React.createContext({ ...defaultAuthContext });
export const AuthProvider = ({ children }: AuthProviderPropsInterface) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [ subdomain, setSubdomain ] = useState<string>('');
    const [ userId, setUserId ] = useState<number | null>(null);
    const [ isLoggedIn, setIsLoggedIn ] = useState<boolean>(false);

    const AuthLogout = useCallback(() => {
        LocalStorage.removeItem(AUTH_ACCESS_TOKEN_KEY);
        LocalStorage.removeItem(AUTH_REFRESH_TOKEN_KEY);
        setIsLoggedIn(false);
        navigate(AUTH_URL);
    }, [ navigate ]);

    useEffect(() => {
        const access_token = LocalStorage.getItem(AUTH_ACCESS_TOKEN_KEY);
        const refresh_token = LocalStorage.getItem(AUTH_REFRESH_TOKEN_KEY);
        let isLoading = false;
        let isLogged = isLoggedIn;

        if (!isLoggedIn && access_token && refresh_token) {
            isLoading = true;
            AuthTokenIsValid(access_token).then( async () => {
                setIsLoggedIn(true);
                isLogged = true;
                isLoading = false;
                const { user_id } = jwt_decode(access_token) as { user_id: number };
                setUserId(user_id);

            }).catch(() => {
                AuthRefreshTokenRequest(refresh_token).then((response: any) => {
                    LocalStorage.setItem(AUTH_ACCESS_TOKEN_KEY, response.access);
                    setIsLoggedIn(true);
                    isLogged = true;
                    isLoading = false;
                }).catch(() => {
                    AuthLogout();
                    setIsLoggedIn(false);
                    isLoading = false;
                });
            });
        }

        if (!isLogged && !OPEN_URLS.includes(pathname) && !isLoading) {
            navigate(AUTH_URL);
        }
    }, [ isLoggedIn, AuthLogout, pathname, navigate ]);

    useEffect(() => {
        if (hostname === 'localhost') {
            setSubdomain('pub');
            return;
        }

        const pieces = hostname.split('.');
        const subdomain = pieces[0];
        if (!subdomain) throw new Error('Subdomain is empty');
        setSubdomain(subdomain);
    }, []);

    return (
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn, logout: AuthLogout, AUTH_URL, userId, subdomain }}>
            {children}
        </AuthContext.Provider>
    )
}
