import React, { lazy, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Navbar from './components/navbar/navbar';

import { MainProvider } from './state';
import { navigation } from './app.navigation';
import { ToastsBar } from './components/toastsBar/toastsBar';

import DashboardScreen from './screens/dashboard/DashboardScreen';
const AuthScreen = lazy(() => import('./screens/auth/auth.screen'));
const UserScreen = lazy(() => import('./screens/user/user.screen'));
const SwapScreen = lazy(() => import('./screens/swap/swap.screen'));
const FundScreen = lazy(() => import('./screens/funds/funds.screen'));
const OwnerScreen = lazy(() => import('./screens/owners/owners.screen'));
const BillingScreen = lazy(() => import('./screens/billing/billing.screen'));
const WalletsScreen = lazy(() => import('./screens/wallets/wallets.screen'));
const StrategiesScreen = lazy(() => import('./screens/strategies/strategies.screen'));
const CEXAccountsScreen = lazy(() => import('./screens/cex-accounts/cex-accounts.screen'));
const StableCoinsScreen = lazy(() => import('./screens/stable-coins/stable-coins.screen'));
const OrganizationScreen = lazy(() => import('./screens/organization/organization.screen'));
const SimilaritiesScreen = lazy(() => import('./screens/similarities/similarities.screen'));
const PriceAdjustmentsScreen = lazy(() => import('./screens/price-adjustments/price-adjustments.screen'));
const ProtocolPreferencesScreen = lazy(() => import('./screens/protocol-preferences/protocol-preferences.screen'));

const appName = process.env.REACT_APP_NAME;

function App() {
    console.log('Version: ', process.env.REACT_APP_VERSION);
    return (
        <MainProvider>
            <Navbar
                appName={appName}
                items={navigation}
            />
            <ToastsBar/>
            <Routes>
                <Route path='/' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <DashboardScreen/>
                    </React.Suspense>}/>
                <Route path='/auth/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <AuthScreen/>
                    </React.Suspense>
                }/>
                <Route path='/funds/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <FundScreen/>
                    </React.Suspense>
                }/>
                <Route path='/wallets/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <WalletsScreen/>
                    </React.Suspense>
                }/>
                <Route path='/owners/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <OwnerScreen/>
                    </React.Suspense>
                }/>
                <Route path='/strategies/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <StrategiesScreen/>
                    </React.Suspense>
                }/>
                <Route path='/protocolpreferences/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <ProtocolPreferencesScreen/>
                    </React.Suspense>
                }/>
                <Route path='/priceadjustments/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <PriceAdjustmentsScreen/>
                    </React.Suspense>
                }/>
                <Route path='/similarities/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <SimilaritiesScreen/>
                    </React.Suspense>
                }/>
                <Route path='/stablecoins/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <StableCoinsScreen/>
                    </React.Suspense>
                }/>
                <Route path='/cex-accounts/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <CEXAccountsScreen/>
                    </React.Suspense>
                }/>
                <Route path='/user/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <UserScreen />
                    </React.Suspense>
                }/>
                <Route path='/organization/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <OrganizationScreen />
                    </React.Suspense>
                }/>
                <Route path='/swap/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <SwapScreen />
                    </React.Suspense>
                }/>
                <Route path='/billing/*' element={
                    <React.Suspense fallback={<>Loading</>}>
                        <BillingScreen />
                    </React.Suspense>
                }/>
            </Routes>
        </MainProvider>
    );
}

export default App;
